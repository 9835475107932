//library
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '@components/store/slices/simulator/state';
import nextReducer from '@components/store/slices/simulator/reducers/nextReducer';
import backReducer from '@components/store/slices/simulator/reducers/backReducer';
import setSetReducer from '@components/store/slices/simulator/reducers/setSetReducer';
import setTotalReducer from '@components/store/slices/simulator/reducers/setTotalReducer';
import setQuestionReducer from '@components/store/slices/simulator/reducers/setQuestionReducer';
import setQuoteReducer from '@components/store/slices/simulator/reducers/setQuoteReducer';
import setErrorReducer from '@components/store/slices/simulator/reducers/setErrorReducer';
import setTooglePopupReducer from '@components/store/slices/simulator/reducers/setTooglePopupReducer';
import setIsDrawingReducer from '@components/store/slices/simulator/reducers/setIsDrawingReducer';
import setAssessmentsReducer from '@components/store/slices/simulator/reducers/setAssessmentsReducer';
import setLoadingPercentageReducer from '@components/store/slices/simulator/reducers/setLoadingPercentageReducer';
import addAssessmentToQuoteReducer from '@components/store/slices/simulator/reducers/addAssessmentToQuoteReducer';
import removeAssessmentFromQuoteReducer from '@components/store/slices/simulator/reducers/removeAssessmentFromQuoteReducer';
import resetReducer from '@components/store/slices/simulator/reducers/resetReducer';
import setUtmReducer from '@components/store/slices/simulator/reducers/setUtmReducer';
import getOffer from '@components/store/slices/simulator/actions/getOffer';

/**
 * Create a new slice with initial state and reducers for the simulator.
 * @type {object} Slice
 * @property {string} name - The name of the slice.
 * @property {object} initialState - The initial state for the slice.
 * @property {object} reducers - Reducer functions for the slice.
 * @property {object} extraReducers - Additional reducers defined by the extraReducers function.
 */
export const slice = createSlice({
    name: 'simulator',
    initialState,
    reducers: {
        next: nextReducer,
        back: backReducer,
        setSet: setSetReducer,
        setTotal: setTotalReducer,
        setQuestion: setQuestionReducer,
        setQuote: setQuoteReducer,
        setError: setErrorReducer,
        setTooglePopup: setTooglePopupReducer,
        setIsDrawing: setIsDrawingReducer,
        setAssessments: setAssessmentsReducer,
        addAssessmentToQuote: addAssessmentToQuoteReducer,
        removeAssessmentFromQuote: removeAssessmentFromQuoteReducer,
        setLoadingPercentage: setLoadingPercentageReducer,
        reset: resetReducer,
        setUtm: setUtmReducer
    },
    extraReducers: (builder) => {
        builder.addCase(getOffer.pending, (state) => {
            state.loadingPercentage = 0;
            state.loading[state.quote.contact ? 'confirmGetOffer' : 'getOffer'] = true;
        });
        builder.addCase(getOffer.fulfilled, (state, action) => {
            state.offer = action.payload;
            state.loading[state.quote.contact ? 'confirmGetOffer' : 'getOffer'] = false;
            state.loadingPercentage = 100;
        });
        builder.addCase(getOffer.rejected, (state, action) => {
            const errors: Record<
                string,
                {
                    title: string;
                    message: string;
                }
            > = {
                INVALID_REFERRAL_CODE: {
                    title: 'Invalid Referral Code',
                    message:
                        'Please check for any typing errors or remove the referral code to proceed'
                }
            };

            state.loading[state.quote.contact ? 'confirmGetOffer' : 'getOffer'] = false;
            const errorPayload =
                action.payload && errors[action.payload]
                    ? errors[action.payload]
                    : {
                          title: 'Error',
                          message: `${action.payload}`
                      };
            state.error = { ...errorPayload };
            state.popup.error = true;
        });
    }
});

export const {
    next,
    back,
    setSet,
    setTotal,
    setQuestion,
    setQuote,
    setError,
    setTooglePopup,
    setIsDrawing,
    setAssessments,
    setLoadingPercentage,
    addAssessmentToQuote,
    removeAssessmentFromQuote,
    setUtm,
    reset
} = slice.actions;

export default slice.reducer;
